import { ALLOWED_ACTIONS, ALLOWED_TONES } from './consts';
import { withLocalePrefix } from '../../shared/utils/translation';

const t = withLocalePrefix('ai_tools');

const toTranslatedObject = (str: string, prefix: string) => {
  return {
    value: str,
    text: t(`${prefix}.${str}`),
  };
};

export const AI_ACTIONS = Object.freeze(
  ALLOWED_ACTIONS.map((s) => toTranslatedObject(s, 'actions'))
);

export const TONES = Object.freeze(
  ALLOWED_TONES.map((s) => toTranslatedObject(s, 'tones'))
);
