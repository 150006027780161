import { standardRequestWithErrorFlash } from 'shared/utils/request';
import { ACTION_TYPE, TONE_TYPE } from './consts';

export type Payload = {
  text: string;
  tone_descriptor?: TONE_TYPE;
  product?: string;
};

export const makeAiRequest = async (
  action: ACTION_TYPE,
  payload: Payload
): Promise<string> => {
  const { data } = await standardRequestWithErrorFlash.post(
    `/ai_tools/${action}`,
    payload
  );

  return data.updated_text;
};
