import { Editor, IAllProps } from '@tinymce/tinymce-react';
import React from 'react';
import { locale } from 'shared/utils/translation';

export const getTinyMCELocale = () => {
  const ghrLocale = locale() || 'en';
  if (ghrLocale === 'fr') {
    return 'fr_FR';
  }
  if (ghrLocale === 'pt') {
    return 'pt_BR';
  }
  return ghrLocale;
};

const LocalizedEditor = (props: IAllProps) => {
  const { onChange, initialValue, ...restProps } = props;

  const value = React.useRef(initialValue);

  return (
    <Editor
      {...restProps}
      onChange={onChange}
      init={{
        ...props.init,
        language: getTinyMCELocale(),
      }}
      initialValue={value.current}
    />
  );
};

export default LocalizedEditor;
