import { Editor as tinymce } from 'tinymce';
import { useState } from 'react';
import aiMagicIcon from '../icons/ai_magic';
import {
  ALLOWED_ACTIONS,
  ALLOWED_TONES,
  ACTION_TYPE,
  TONE_TYPE,
  ACTIONS_OPTIONS,
} from '../lib/consts';

type MenuItem = {
  type: string;
  text: string;
  onAction: () => void;
};

type SubMenu = {
  type: string;
  text: string;
  getSubmenuItems: () => Array<MenuItem>;
};

type Menu = Array<MenuItem | SubMenu>;

export type Option = {
  value: '' | ACTION_TYPE | TONE_TYPE;
  text: string;
};

export type AiAssistant = {
  // State
  action: ACTION_TYPE | '';
  selectedTone: TONE_TYPE | '';
  selectedText: string;
  editor: tinymce | null;
  selectedNode: null | HTMLElement;
  showPopper: boolean;
  popperOptions: {
    menuOptions: Option[];
    toneOptions: Option[];
  };
  // Functions
  onAction: (
    tinyEditor: tinymce,
    actionName: ACTION_TYPE,
    toneName?: TONE_TYPE
  ) => void;
  registerAiMenu: (tinyEditor: tinymce) => void;
  close: () => void;
};

const useAiAssistant = (): AiAssistant => {
  const [action, setAction] = useState<ACTION_TYPE | ''>('');
  const [selectedTone, setSelectedTone] = useState<TONE_TYPE | ''>('');
  const [selectedText, setSelectedText] = useState<string>('');
  // We need a reference to the editor in order to do editor-specific actions
  const [editor, setEditor] = useState<tinymce | null>(null);
  const [selectedNode, setSelectedNode] = useState<null | HTMLElement>(null);
  const [showPopper, setShowPopper] = useState(false);
  const [popperOptions, setPopperOptions] = useState({
    menuOptions: [],
    toneOptions: [],
  });

  const onAction = (
    tinyEditor: tinymce,
    actionName: ACTION_TYPE,
    toneName: TONE_TYPE | '' = ''
  ) => {
    // A "Caret" here means that the user has not selected a range of text
    // and we will just set all the content as our selection
    const noRangeSelected = tinyEditor.selection.getSel()?.type === 'Caret';

    if (noRangeSelected) {
      tinyEditor.selection.select(tinyEditor.getBody(), true);
    }

    const selection = tinyEditor.selection.getContent({ format: 'html' });

    setAction(actionName);
    setSelectedTone(toneName);
    setSelectedText(selection);
    setEditor(tinyEditor);
    setSelectedNode(tinyEditor.selection.getNode());
    setShowPopper(true);
  };

  const toneSubmenu = (
    menuOption: Option,
    tones: Option[],
    tinyEditor: tinymce
  ) => {
    return {
      type: 'nestedmenuitem',
      text: menuOption.text,
      getSubmenuItems: () => {
        return tones.map((tone) => {
          return {
            type: 'menuitem',
            text: tone.text,
            onAction: () => {
              onAction(tinyEditor, menuOption.value as TONE_TYPE, tone.value);
            },
          };
        });
      },
    };
  };

  const sortByText = (a: Option, b: Option) => a.text.localeCompare(b.text);

  const registerAiMenu = (tinyEditor: tinymce) => {
    const menuOptions = tinyEditor
      .getParam('ai_assistant_actions', [])
      .filter((item: Option) => ALLOWED_ACTIONS.includes(item.value))
      .sort(sortByText);
    const toneOptions = tinyEditor
      .getParam('ai_assistant_tones', [])
      .filter((tone: Option) => ALLOWED_TONES.includes(tone.value))
      .sort(sortByText);

    setPopperOptions({ menuOptions, toneOptions });

    const menuItems: Menu = [];

    // Add change tone option first so ordering matches the AiAssistantPopper
    const changeToneOption = menuOptions.find(
      (option: Option) => ACTIONS_OPTIONS.change_tone === option.value
    );
    if (changeToneOption && toneOptions.length) {
      menuItems.push(toneSubmenu(changeToneOption, toneOptions, tinyEditor));
    }

    menuOptions.forEach((menuOption: Option) => {
      if (ACTIONS_OPTIONS.change_tone !== menuOption.value) {
        menuItems.push({
          type: 'menuitem',
          text: menuOption.text,
          onAction: () => {
            onAction(tinyEditor, menuOption.value as ACTION_TYPE);
          },
        });
      }
    });

    if (menuItems.length) {
      tinyEditor.ui.registry.addIcon('aiMagicIcon', aiMagicIcon);

      tinyEditor.ui.registry.addMenuButton('ai_assistant', {
        icon: 'aiMagicIcon',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fetch: (callback: any) => callback(menuItems),
      });
    }
  };

  const close = () => {
    setAction('');
    setSelectedTone('');
    setSelectedText('');
    setShowPopper(false);
  };

  return {
    // State
    action,
    selectedTone,
    selectedText,
    editor,
    selectedNode,
    showPopper,
    popperOptions,
    // Functions
    onAction,
    registerAiMenu,
    close,
  };
};

export default useAiAssistant;
