const summarize_text = 'summarize_text';
const expand_text = 'expand_text';
const grammar_check = 'grammar_check';
const change_tone = 'change_tone';
const revise_text = 'revise_text';

export const ALLOWED_ACTIONS = Object.freeze([
  summarize_text,
  expand_text,
  grammar_check,
  change_tone,
  revise_text,
]);
export type ACTION_TYPE = typeof ALLOWED_ACTIONS[number];

export const ACTIONS_OPTIONS = Object.freeze({
  summarize_text,
  expand_text,
  change_tone,
  grammar_check,
  revise_text,
});

export const ALLOWED_TONES = Object.freeze([
  'friendly',
  'professional',
  'enthusiastic',
  'formal',
  'concise',
  'informative',
]);
export type TONE_TYPE = typeof ALLOWED_TONES[number];

// eslint-disable-next-line no-shadow
export const enum AI_PRODUCT {
  GHSA = 'ghsa',
  CANDIDATE_PROFILE_EMAIL = 'candidate_profile_email',
  JOB_KICKOFF = 'job_kickoff',
  STAGE_TRANSITION_RULES = 'stage_transition_rules',
}
