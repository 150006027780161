import wordFilter from 'tinymce-word-paste-filter';
import tinymce from 'tinymce';

tinymce.PluginManager.add('ghr_powerpaste', (editor) => {
  editor.on('paste', (e) => {
    e.preventDefault();

    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const isHtml = !!clipboardData.getData('text/html');

    const pastedData =
      clipboardData.getData('text/html') || clipboardData.getData('text/plain');
    const cleanedContent = wordFilter(pastedData);

    const clipboardContent = isHtml
      ? { html: cleanedContent }
      : { text: cleanedContent };

    editor.execCommand('mceInsertClipboardContent', false, clipboardContent);
  });
});
