import tinyMCE from 'tinymce';
import 'tinymce/models/dom';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import './plugins/ghr_powerpaste';

import 'tinymce/skins/ui/tinymce-5/skin.css';
// @ts-expect-error - TS2307 - Cannot find module
import skinCss from '!css-loader!sass-loader!tinymce/skins/ui/tinymce-5/skin.css';
// @ts-expect-error - TS2307 - Cannot find module
import skinUiContentCss from '!css-loader!sass-loader!tinymce/skins/ui/tinymce-5/content.css';
// @ts-expect-error - TS2307 - Cannot find module
import skinContentCss from '!css-loader!sass-loader!tinymce/skins/content/tinymce-5/content.css';

import packageJson from 'tinymce/package.json';

// @ts-expect-error - TS2307 - Cannot find module
import defaultCss from '!css-loader!sass-loader!./tinymce-extras/default.scss';
// @ts-expect-error - TS2307 - Cannot find module
import emailCss from '!css-loader!sass-loader!./tinymce-extras/email.scss';
// @ts-expect-error - TS2307 - Cannot find module
import aiEmailCss from '!css-loader!sass-loader!./tinymce-extras/ai_email.scss';
// @ts-expect-error - TS2307 - Cannot find module
import interviewKitCss from '!css-loader!sass-loader!./tinymce-extras/interview_kits_editor.scss';
// @ts-expect-error - TS2307 - Cannot find module
import marginCss from '!css-loader!sass-loader!./tinymce-extras/margin.scss';
// @ts-expect-error - TS2307 - Cannot find module
import standardFormattingCss from '!css-loader!sass-loader!./tinymce-extras/standard_formatting.scss';

import './langs/de';
import './langs/es';
import './langs/fr_FR';
import './langs/it';
import './langs/pt_BR';

import { AI_ACTIONS, TONES } from './lib/ai_action_consts';
import $ from 'legacy/jquery';

type Editor = {
  on: (arg0: string, arg1: () => void) => void;
};

const Defaults = {
  cache_suffix: `?v=${packageJson.version}`,
  selector: '.editor',
  browser_spellcheck: true,
  contextmenu: false,
  deprecation_warnings: process.env.NODE_ENV !== 'production',
  branding: false,
  promotion: false,
  content_css: false,
  skin: false,
  removed_menuitems: 'backcolor',
  font_size_input_default_unit: 'px',
  content_style: contentStyles(),
  text_patterns: false,
} as const;

const Email = {
  ...Defaults,
  convert_urls: false,
  plugins: 'ghr_powerpaste table image code link lists',
  menubar: false,
  toolbar:
    'bold italic underline fontsize | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image code',
  statusbar: false,
  font_size_formats: '11px 12px 14px 16px 18px 22px 26px 32px',
  height: 200,
  content_style: contentStyles(emailCss),
} as const;

function contentStyles(...cssFiles: { toString: () => string }[]) {
  return [skinCss, skinUiContentCss, skinContentCss, ...cssFiles]
    .map((cssFile) => cssFile.toString())
    .join('\n');
}

const TinyMCEConfig = {
  Shed: {
    ...Defaults,
    plugins: 'ghr_powerpaste table image media code link lists',
    menubar: 'edit insert format tools',
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image media code',
    content_style: contentStyles(interviewKitCss),
  },
  GenericField: {
    ...Defaults,
    height: 268,
    plugins: 'ghr_powerpaste table image media code link',
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image code',
    toolbar_items_size: 'small',
    menubar: false,
    content_style: contentStyles(defaultCss, marginCss),
  },
  JobBoard: {
    ...Defaults,
    height: 268,
    plugins: 'ghr_powerpaste table image media code link lists autolink',
    menubar: 'edit insert format tools',
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image media code',
    default_link_target: '_blank',
    content_style: contentStyles(defaultCss, marginCss),
  },
  HiringPlanGenericField: {
    ...Defaults,
    height: 268,
    plugins: 'ghr_powerpaste table image media code link lists',
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image media code',
    toolbar_items_size: 'small',
    menubar: false,
    content_style: contentStyles(defaultCss, marginCss),
  },
  StandardEmail: {
    ...Defaults,
    convert_urls: false,
    plugins: 'ghr_powerpaste table image media code link lists',
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image media code',
    toolbar_items_size: 'small',
    menubar: false,
    height: 408,
    statusbar: false,
    content_style: contentStyles(defaultCss, marginCss),
  },
  InterviewKit: {
    ...Defaults,
    width: 700,
    height: 450,
    plugins: 'ghr_powerpaste table image media code link lists',
    menubar: 'edit insert format tools',
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image media code',
    content_style: contentStyles(interviewKitCss),
    table_border_styles: [{ title: 'Solid', value: 'solid' }],
    table_appearance_options: false,
  },
  ExternalJobBoard: {
    ...Defaults,
    plugins: 'ghr_powerpaste table lists',
    menubar: false,
    toolbar: 'bold italic underline | bullist numlist',
    valid_elements: 'p,strong/b,em/i,u,ol,ul,li,br',
    statusbar: false,
  },
  Email: Email,
  AiEmail: {
    ...Email,
    plugins: 'ghr_powerpaste table image code link lists ai_assistant',
    toolbar:
      'bold italic underline fontsize | alignleft aligncenter alignright | bullist numlist | outdent indent | ai_assistant| hr | link image code',
    ai_assistant_actions: AI_ACTIONS,
    ai_assistant_tones: TONES,
    content_style: contentStyles(aiEmailCss),
  },
  EmailWithPreview: {
    ...Defaults,
    plugins: 'ghr_powerpaste table image code link lists',
    menubar: false,
    toolbar:
      'bold italic underline fontsize | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image code',
    statusbar: false,
    font_size_formats: '11px 12px 14px 16px 18px 22px 26px 32px',
    link_assume_external_targets: true,
    content_style: contentStyles(standardFormattingCss),
  },
  Legible: {
    ...Defaults,
    plugins: 'ghr_powerpaste table image code link lists',
    menubar: false,
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link image code',
    statusbar: false,
    content_style: contentStyles(standardFormattingCss),
  },
  CustomQuestion: {
    ...Defaults,
    plugins: 'ghr_powerpaste table image media code link ',
    toolbar:
      'styles | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | link | code',
    toolbar_items_size: 'small',
    menubar: false,
    content_style: contentStyles(defaultCss, marginCss),
  },
  PayTransparencyRule: {
    ...Defaults,
    plugins: 'ghr_powerpaste table image code link lists',
    menubar: false,
    toolbar:
      'bold italic underline fontsize | alignleft aligncenter alignright | bullist numlist | outdent indent | hr | link',
    statusbar: false,
    font_size_formats: '11px 12px 14px 16px 18px 22px 26px 32px',
    height: 140,
  },

  initEmail: function (
    container: string,
    onInit?: (editor: Editor) => void,
    customConfig?: any
  ) {
    const editorId = $(container).find('.editor').attr('id');
    let config = { ...TinyMCEConfig.Email, ...(customConfig || {}) };

    tinyMCE.EditorManager.execCommand('mceRemoveEditor', false, editorId);

    if (typeof onInit === 'function') {
      config = {
        ...config,
        setup: function (editor: Editor) {
          editor.on('init', function () {
            onInit(editor);
          });
        },
      };
    }

    tinyMCE.init(TinyMCEConfig.LocalizedConfig(config));
  },

  LocalizedConfig: function (configObj: any, overrides = {}) {
    function getTinyMCELocale() {
      const ghrLocale = window?.Greenhouse?.Translation?.locale || 'en';
      if (ghrLocale === 'fr') {
        return 'fr_FR';
      }
      if (ghrLocale === 'pt') {
        return 'pt_BR';
      }
      return ghrLocale;
    }

    const languageOverride = {
      language: getTinyMCELocale(),
    };

    return {
      ...configObj,
      ...overrides,
      ...languageOverride,
    };
  },
};

// @ts-expect-error - TS2339: Property 'tinyMCE' does not exist on type 'Window & typeof globalThis'.
window.tinyMCE = tinyMCE;

export default TinyMCEConfig;
