import React from 'react';
import { withLocalePrefix } from '../../../shared/utils/translation';
import { ACTION_TYPE, TONE_TYPE, ACTIONS_OPTIONS } from '../../lib/consts';
import {
  EllipsisMenuButton,
  ExpandableMenu,
  MenuItem,
  MenuList,
  SubMenu,
  SubMenuItem,
  ParentMenuItem,
} from '@grnhse/seedling/lib/birch/components/expandable_menu';
import type { Option } from '../../hooks/useAiAssistant';

const t = withLocalePrefix('ai_tools.actions');

type ActionMenuProps = {
  onMenuPick: (action: {
    enhanceMethod: ACTION_TYPE;
    tone?: TONE_TYPE;
  }) => void;
  options: { menuOptions: Option[]; toneOptions: Option[] };
};

const ActionMenu = ({ onMenuPick, options }: ActionMenuProps) => {
  const renderSubMenu = () => {
    const changeToneOption = options.menuOptions.find(
      (option: Option) => ACTIONS_OPTIONS.change_tone === option.value
    );
    if (!changeToneOption || !options.toneOptions.length) {
      return;
    }

    return (
      <SubMenu
        parent={
          <ParentMenuItem onSelect={() => {}}>
            {t('change_tone')}
          </ParentMenuItem>
        }
      >
        {options.toneOptions.map((toneOption) => (
          <SubMenuItem
            key={toneOption.value}
            onSelect={() => {
              onMenuPick({
                enhanceMethod: ACTIONS_OPTIONS.change_tone,
                tone: toneOption.value,
              });
            }}
          >
            {toneOption.text}
          </SubMenuItem>
        ))}
      </SubMenu>
    );
  };

  const renderMenu = () => {
    const menuOptions = options.menuOptions.filter(
      (option) =>
        ![
          ACTIONS_OPTIONS.grammar_check,
          ACTIONS_OPTIONS.change_tone,
          ACTIONS_OPTIONS.revise_text,
        ].includes(option.value)
    );

    if (!menuOptions.length) {
      return;
    }

    return (
      <>
        {menuOptions.map((menuOption) => (
          <MenuItem
            key={menuOption.value}
            onSelect={() => {
              onMenuPick({
                enhanceMethod: menuOption.value,
              });
            }}
          >
            {menuOption.text}
          </MenuItem>
        ))}
      </>
    );
  };

  return (
    <ExpandableMenu>
      <EllipsisMenuButton data-provides={'ai-popper-menu-button'} />
      <MenuList>
        {renderSubMenu()}
        {renderMenu()}
      </MenuList>
    </ExpandableMenu>
  );
};

export default ActionMenu;
