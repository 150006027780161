import { Editor, IAllProps } from '@tinymce/tinymce-react';
import { Editor as tinymce } from 'tinymce';
import React, { useRef } from 'react';
import { getTinyMCELocale } from './localized_editor';
import AiAssistantPopper from './components/ai_assistant/ai_assistant_popper';
import useAiAssistant, { AiAssistant } from './hooks/useAiAssistant';
import { AI_PRODUCT } from './lib/consts';

type IAllPropsWithAi = IAllProps & {
  aiProduct?: AI_PRODUCT;
};

const LocalizedEditorWithAi = (props: IAllPropsWithAi) => {
  const { onChange, initialValue, ...restProps } = props;
  const value = useRef(initialValue);
  const anchorEl = useRef(null);

  const {
    action,
    selectedTone,
    selectedText,
    editor,
    selectedNode,
    showPopper,
    popperOptions,
    registerAiMenu,
    close,
  }: AiAssistant = useAiAssistant();

  const setup = (tinyEditor: tinymce) => {
    registerAiMenu(tinyEditor);
    if (typeof props.init?.setup === 'function') {
      props.init.setup(tinyEditor);
    }
  };

  return (
    <div className="editor-container" ref={anchorEl}>
      <Editor
        {...restProps}
        onChange={onChange}
        init={{
          ...props.init,
          language: getTinyMCELocale(),
          setup: setup,
        }}
        initialValue={value.current}
      />
      {editor && anchorEl?.current && selectedNode && (
        <AiAssistantPopper
          options={popperOptions}
          action={action}
          selectedText={selectedText}
          selectedTone={selectedTone}
          editor={editor}
          anchorEl={anchorEl.current}
          selectedNode={selectedNode}
          open={showPopper}
          close={close}
          product={props.aiProduct}
        />
      )}
    </div>
  );
};

export default LocalizedEditorWithAi;
